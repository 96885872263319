import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import './App.css';
import FormViagem from './FormViagem';

function App() {
  return (
    <FormViagem />
  );
}

export default App;
