import React from 'react';

interface InputProps {
    mensagem: string;
}

function PaginaMensagem(props: InputProps) {
    return (
        <h1>{props.mensagem}</h1>
    );
}

export default PaginaMensagem;